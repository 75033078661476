import React, { useEffect, useRef, useState } from 'react'
import styles from './challengeSteps.module.scss'
import Robotik from '../../../../icons/robbot.svg'
import cx from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import Vector from '../../../../icons/vectorBottom.svg'
import {
  setAuthPerson,
  setCareQuizQuestions,
  setCommerciallyActive,
  setEditFromSummary,
  setLegalRepresentation,
  setPrivateIndividual,
  setRepresentativePerson,
} from '../../../../redux/actions/quizQuestions'
import {
  setAuthorizedPerson,
  setCareReceipients,
  setCommercially,
  setEmptyPrivate,
  setIndividual,
  setLegalRepresentations,
  setRepresentative,
} from '../../../../redux/actions/careReceipients'
import Details from '../Details'
import CareChallenge from '../CareChallenge'
import LegalChallenge from '../LegalChallenge'
import AuthChallenge from '../AuthChallenge'
import RepresentativeChallenge from '../RepresentativeChallenge'
import CommerciallyActiveChallenge from '../CommerciallyActiveChallenge'
import PageComponent from '../../../Pages/PageComponent'
import PrivateIndividualChallenge from '../PrivateIndividualChallenge'
import ArrowRight from '../../../../icons/arrowRight.svg'
import TypingEffect from '../TypingEffect'
import Logo from '../../../../icons/miniLogo.svg'
import {
  listEntryRepresentationByPrivateIndividual,
  representativeQuestions,
} from '../../../../constants/quizConstant'
const ChallengeSteps = () => {
  const dispatch = useDispatch()
  const { pathname } = window.location
  const {
    quizCareReceipients: { quizCare, indexCare },
    quizLegalRepresentation: { quizLegal, indexLegal },
    quizAuthorizedPerson: { quizAuth, indexAuth },
    quizRepresentativePerson: { quizRep, indexRep },
    commerciallyActive: { quizActive, indexActive },
    privateIndividual: { quizPrivate, indexPrivate },
    editSummary,
    index,
    groupName,
    availableDays,
  } = useSelector((state) => state.quizQuestions)
  const {
    careReceipients,
    legalRepresentations,
    authorizedPerson,
    representativePerson,
    representationByCommerciallyActive,
    representationByPrivateIndividual,
    authorizedPerson: { periodOfAbsenceFrom, periodOfAbsenceTo },
    nonActiveBtn: { activeBtn },
  } = useSelector((state) => state)
  const [parentState, setParentState] = useState('')
  const [edit, setEdit] = useState(-1)
  const [editList, setEditList] = useState(-1)
  const [textAreaValue, setTextAreaValue] = useState('')
  const [showScrollDownButton, setShowScrollDownButton] = useState(false)
  const [windowWidth, setWindowWidth] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const listRef = useRef(null) // Reference to the list container
  const isDisabledCare = quizCare.some(
    (item, i) => i === indexCare && item.required === true,
  )
  const isDisabledLegal = quizLegal.some(
    (item, i) => i === indexLegal && item.required === true,
  )
  const isDisabledAuth = quizAuth.some(
    (item, i) => i === indexAuth && item.required === true,
  )
  const isDisabledRep = quizRep.some(
    (item, i) => i === indexRep && item.required === true,
  )
  const isDisabledActive = quizActive.some(
    (item, i) => i === indexActive && item.required === true,
  )
  const isDisabledPrivate = quizPrivate.some(
    (item, i) => i === indexPrivate && item.required === true,
  )
  function findFirstUnansweredIndex(quizArray) {
    const questionIndex = quizArray?.findIndex(
      (item) =>
        item?.answers === '' ||
        (typeof item?.answers === 'object' && !item?.answers?.date) ||
        (item?.question ===
          'Wurde bereits ein Pflegegrad erteilt ? Wenn ja, welcher ? Bitte wählen Sie aus der Liste aus.' &&
          item?.answers === 'Nein') ||
        (item?.question ===
          'Wurde bereits ein Pflegegrad erteilt ? Wenn ja, welcher ? Bitte wählen Sie aus der Liste aus.' &&
          item?.answers === '1') ||
        (item?.question ===
          'Wird die verhinderte Pflegeperson vom Pflegebedürftigen als Pfleger bezahlt oder ist sogar eine Pflegefirma ?' &&
          item?.answers === 'Ja') ||
        item?.answers === 'Unter 6 monate',
    )
    return questionIndex >= 0 ? questionIndex : quizArray?.length
  }
  const notCareAnsweredIndex = findFirstUnansweredIndex(quizCare)
  const notLegalAnsweredIndex = findFirstUnansweredIndex(quizLegal)
  const notAuthAnsweredIndex = findFirstUnansweredIndex(quizAuth)
  const notRepAnsweredIndex = findFirstUnansweredIndex(quizRep)
  const notActiveAnsweredIndex = findFirstUnansweredIndex(quizActive)
  const notPrivateAnsweredIndex = findFirstUnansweredIndex(quizPrivate)
  const messageRefs = useRef({})
  const message =
    'Bitte teilen Sie mir die Details zu der verrichteten Verhinderungspflege mit.'
  const helloMessage =
    'Hallo, mein Name ist FLEXX-i und ich freue mich, Ihnen zu helfen! Lassen Sie uns mit der Erstellung Ihres Antrags auf Verhinderungspflege beginnen. Beantworten Sie mir bitte folgende Fragen:'
  let notObjIndex =
    indexPrivate - representationByPrivateIndividual.individualDates?.length
  let removedItem =
    indexPrivate - representationByPrivateIndividual.individualDates.length

  let notObjIndexActive =
    indexActive - representationByCommerciallyActive?.activeDates?.length
  const [dates, setDates] = useState([])
  const [currentIndexActive, setCurrentIndexActive] = useState(0)
  const [currentIndexIndividual, setCurrentIndexIndividual] = useState(0)

  useEffect(() => {}, [
    representationByPrivateIndividual.representingPersonByTransport,
  ])

  useEffect(() => {
    setCurrentIndexActive(
      representationByCommerciallyActive?.activeDates?.length - 1,
    )
  }, [representationByCommerciallyActive?.activeDates?.length])

  useEffect(() => {
    setCurrentIndexIndividual(
      representationByPrivateIndividual?.individualDates?.length - 1,
    )
  }, [representationByPrivateIndividual?.individualDates?.length])

  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split('.')
    const date = new Date(year, month - 1, day)
    return date.toString()
  }
  const start = new Date(convertToDate(authorizedPerson.periodOfAbsenceFrom))
  const end = new Date(convertToDate(authorizedPerson.periodOfAbsenceTo))

  // Get the difference in time
  const differenceInTime = end - start + 1
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  // Function to generate the date range iteratively to avoid misordering
  const generateDateRange = (start, end) => {
    const startDate = new Date(start.split('.').reverse().join('-')) // Convert 'DD.MM.YYYY' to 'YYYY-MM-DD'
    const endDate = new Date(end.split('.').reverse().join('-'))
    const dateArray = []

    let currentDate = startDate
    while (currentDate <= endDate) {
      dateArray.push(formatDate(currentDate)) // Add formatted date
      currentDate.setDate(currentDate.getDate() + 1) // Move to the next day
    }

    return dateArray
  }

  // Generate the date interval when the component mounts
  useEffect(() => {
    const startDate = convertToDate(periodOfAbsenceFrom)
    const endDate = convertToDate(periodOfAbsenceTo)
    const generatedDates = generateDateRange(startDate, endDate)
    setDates(generatedDates) // Set the generated dates in state
  }, [periodOfAbsenceFrom, periodOfAbsenceTo])
  const handleResize = () => {
    setWindowWidth({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (messageRefs.current[`${groupName}${index}`] && editSummary) {
      messageRefs.current[`${groupName}${index}`].scrollIntoView(true)
    }
  }, [])

  useEffect(() => {}, [
    careReceipients,
    legalRepresentations,
    authorizedPerson,
    representativePerson,
    representationByCommerciallyActive,
    representationByPrivateIndividual,
  ])
  const isAtBottom = () => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current
    return Math.ceil(scrollTop + clientHeight) >= scrollHeight
  }

  const isAtTop = () => {
    const { scrollTop } = listRef.current
    return scrollTop === 0
  }

  const handleScroll = () => {
    if (listRef.current) {
      const atBottom = isAtBottom()
      const atTop = isAtTop()
      if (!atBottom && !atTop) {
        setShowScrollDownButton(true)
      } else {
        setShowScrollDownButton(false)
      }
    }
  }

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      })
      setShowScrollDownButton(false)
    }
  }

  useEffect(() => {
    const list = listRef.current
    if (list) {
      list.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (list) {
        list.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  const handleEditText = (index, quizType, setFunction, indexType) => {
    const newQuiz = quizType.map((item, i) => {
      if (i === index) {
        dispatch(setEditFromSummary(item.answers))
        return {
          ...item,
          edit: true,
        }
      }
      return item
    })
    dispatch(setFunction(newQuiz, indexType))
  }
  const handleEditCare = (index) => {
    handleEditText(index, quizCare, setCareQuizQuestions, indexCare)
  }
  const handleEditeLegal = (index) => {
    handleEditText(index, quizLegal, setLegalRepresentation, indexLegal)
  }

  const handleEditAuth = (index) => {
    handleEditText(index, quizAuth, setAuthPerson, indexAuth)
  }

  const handleEditRep = (index) => {
    handleEditText(index, quizRep, setRepresentativePerson, indexRep)
  }

  const handleEditActive = (index) => {
    handleEditText(index, quizActive, setCommerciallyActive, indexActive)
  }

  const handleEditPrivate = (index) => {
    handleEditText(index, quizPrivate, setPrivateIndividual, indexPrivate)
  }
  const getTextValue = (e) => {
    if (e.currentTarget.value === '') {
      setTextAreaValue('-')
    } else {
      setTextAreaValue(e.currentTarget.value)
    }
  }
  const handleCancel = (index, quizType, setFunction, indexType) => {
    const newQuiz = quizType.map((item, i) => {
      if (i === index) {
        dispatch(setEditFromSummary(''))

        return {
          ...item,
          edit: false,
        }
      }
      return item
    })
    dispatch(setFunction(newQuiz, indexType))
  }
  const handleCancelCare = (index) => {
    handleCancel(index, quizCare, setCareQuizQuestions, indexCare)
  }
  const handleCancelLegal = (index) => {
    handleCancel(index, quizLegal, setLegalRepresentation, indexLegal)
  }

  const handleCancelAuth = (index) => {
    handleCancel(index, quizAuth, setAuthPerson, indexAuth)
  }

  const handleCancelRep = (index) => {
    handleCancel(index, quizRep, setRepresentativePerson, indexRep)
  }

  const handleCancelActive = (index) => {
    handleCancel(index, quizActive, setCommerciallyActive, indexActive)
  }

  const handleCancelPrivate = (index) => {
    handleCancel(index, quizPrivate, setPrivateIndividual, indexPrivate)
  }
  const handleTextAnswer = (
    index,
    quizType,
    setFunction,
    setAnswersFunction,
    keyObject,
    indexType,
    returnQuestionLogic = null,
    hhh,
    questionKey,
    activeKey,
  ) => {
    console.log(questionKey)
    const newQuiz = quizType.map((item, i) => {
      if (!textAreaValue && i === index) {
        dispatch(setEditFromSummary(''))
        return {
          ...item,
          answers: item.answers,
          edit: false,
        }
      } else if (i === index) {
        dispatch(setEditFromSummary(''))
        return {
          ...item,
          answers: textAreaValue,
          // returnQuestion:
          //   (item.question ===
          //     'Wurde bereits ein Pflegegrad erteilt ? Wenn ja, welcher ? Bitte wählen Sie aus der Liste aus.' &&
          //     textAreaValue === 'Nein') ||
          //   textAreaValue === '1'
          //     ? 'Ersatz von Kosten der Verhinderungspflege ist derzeit nicht möglich, mindestens Pflegegrad 2 ist erforderlich.'
          //     : '',
          edit: false,
        }
      }
      return item
    })

    const keys = Object.keys(representationByPrivateIndividual) // Get an array of keys
    const indexByKeyP = keys.indexOf(questionKey)

    const keysActive = Object.keys(representationByCommerciallyActive) // Get an array of keys
    const indexByKeyA = keysActive.indexOf(questionKey)

    hhh
      ? dispatch(
          setAnswersFunction(
            Object.keys(keyObject)[indexByKeyP],
            !textAreaValue ? Object.values(keyObject)[index] : textAreaValue,
          ),
        )
      : activeKey
        ? dispatch(
            setAnswersFunction(
              Object.keys(keyObject)[indexByKeyA],
              !textAreaValue ? Object.values(keyObject)[index] : textAreaValue,
            ),
          )
        : dispatch(
            setAnswersFunction(
              Object.keys(keyObject)[index],
              !textAreaValue ? Object.values(keyObject)[index] : textAreaValue,
            ),
          )
    dispatch(setFunction(newQuiz, indexType))
    setTextAreaValue('')
  }

  const handleCareTextAnswer = (index) => {
    handleTextAnswer(
      index,
      quizCare,
      setCareQuizQuestions,
      setCareReceipients,
      careReceipients,
      indexCare,
    )
  }

  const handleLegalTextAnswer = (index) => {
    handleTextAnswer(
      index,
      quizLegal,
      setLegalRepresentation,
      setLegalRepresentations,
      legalRepresentations,
      indexLegal,
    )
  }

  const handleAuthTextAnswer = (index) => {
    const authReturnQuestionLogic = (value) =>
      value === 'Ja'
        ? 'Erstattung der Verhinderungspflege ist erst ab 6 Monaten Vorpflegezeit möglich'
        : ''
    handleTextAnswer(
      index,
      quizAuth,
      setAuthPerson,
      setAuthorizedPerson,
      authorizedPerson,
      indexAuth,
      authReturnQuestionLogic,
    )
  }

  const handleRepTextAnswer = (index) => {
    const repReturnQuestionLogic = (value) => {
      if (value === 'Nein') {
        return 'Vertretende Person ist privat und nur gelegentlich als PflegerIn tätig.'
      }
      if (value !== 'nicht verwandt') {
        return 'Ersatz von Kosten der Verhinderungspflege ist bei Personen in Verwandschaft, Lebensgemeinschaft und Ehepartnern nur eingeschränkt möglich.'
      }
      if (value !== 'Nicht verheiratet oder verschwägert') {
        return 'Ersatz von Kosten der Verhinderungspflege ist bei Verschwägerten Personen nur eingeschränkt möglich.'
      }
      if (value === 'Ja') {
        return 'Ersatz von Kosten der Verhinderungspflege ist bei dauernd im gleichen Haushalt lebenden Personen nur eingeschränkt möglich.'
      }
      return ''
    }
    handleTextAnswer(
      index,
      quizRep,
      setRepresentativePerson,
      setRepresentative,
      representativePerson,
      indexRep,
      repReturnQuestionLogic,
    )
  }

  const handleActiveTextAnswer = (index, questionKey) => {
    const activeKey = !!quizActive.find((q) => q.questionKey === questionKey)

    handleTextAnswer(
      index,
      quizActive,
      setCommerciallyActive,
      setCommercially,
      representationByCommerciallyActive,
      indexActive,
      null,
      null,
      questionKey,
      activeKey,
    )
  }

  const handlePrivateTextAnswer = (index, questionKey) => {
    const hhh = !!quizPrivate.find((q) => q.questionKey === questionKey)
    handleTextAnswer(
      index,
      quizPrivate,
      setPrivateIndividual,
      setIndividual,
      representationByPrivateIndividual,
      indexPrivate,
      null,
      hhh,
      questionKey,
    )
  }
  const handleSkip = () => {
    // Object.values(representationByCommerciallyActive)[0][notObjIndex + 1]?.availableDates.date && setCurrentIndex(currentIndex + 1)
    if (quizCare.length !== indexCare) {
      const newCareQuiz = quizCare.map((item, i) => {
        if (i === indexCare) {
          return {
            ...item,
            answers: '-',
            fileName: '-',
          }
        }
        return item
      })
      dispatch(setCareReceipients(Object.keys(careReceipients)[indexCare], '-'))
      dispatch(setCareQuizQuestions(newCareQuiz, indexCare + 1))
      setParentState('')
    } else {
      if (
        careReceipients.legalRepresentation === 'Ja' &&
        quizLegal.length !== indexLegal
      ) {
        const newLegalQuiz = quizLegal.map((item, i) => {
          if (i === indexLegal) {
            return {
              ...item,
              answers: '-',
              fileName: '-',
            }
          }
          return item
        })
        dispatch(
          setLegalRepresentations(
            Object.keys(legalRepresentations)[indexLegal],
            '-',
          ),
        )
        dispatch(setLegalRepresentation(newLegalQuiz, indexLegal + 1))
        setParentState('')
      } else {
        if (quizAuth.length !== indexAuth) {
          const newAuthQuiz = quizAuth.map((item, i) => {
            if (i === indexAuth) {
              return {
                ...item,
                answers: '-',
              }
            }
            return item
          })
          dispatch(
            setAuthorizedPerson(Object.keys(authorizedPerson)[indexAuth], '-'),
          )
          dispatch(setAuthPerson(newAuthQuiz, indexAuth + 1))
          setParentState('')
        } else {
          if (quizRep.length !== indexRep) {
            const newRepQuiz = quizRep.map((item, i) => {
              if (i === indexRep) {
                return {
                  ...item,
                  answers: '-',
                }
              }
              return item
            })
            dispatch(
              setRepresentative(Object.keys(representativePerson)[indexRep], '-'),
            )
            dispatch(setRepresentativePerson(newRepQuiz, indexRep + 1))
            setParentState('')
          } else {
            if (
              representativePerson.personRepresenting === 'Ja' &&
              quizActive.length !== indexActive
            ) {
              const newActiveQuiz = quizActive.map((item, i) => {
                if (i === indexActive) {
                  return {
                    ...item,
                    answers:
                      typeof item.answers === 'object'
                        ? {
                            date: dates[currentIndexActive],
                            duration: '0 Std.',
                            amount: '€ 0',
                          }
                        : '-',
                  }
                }
                return item
              })

              if (
                availableDays &&
                representationByCommerciallyActive.activeDates.length !==
                  availableDays &&
                (representationByCommerciallyActive.activeDates[
                  representationByCommerciallyActive.activeDates.length - 1
                ].availableDates.amount ||
                  representationByCommerciallyActive.activeDates[
                    representationByCommerciallyActive.activeDates.length - 1
                  ].availableDates.amount === '0')
              ) {
                newActiveQuiz.splice(
                  indexActive + 1,
                  0,
                  {
                    answers: {
                      date: '',
                      duration: '',
                      amount: '',
                    },
                    img: Logo,
                    type: 'group',
                    required: false,
                    questionKey: 'availableDates',
                  },
                  {
                    question:
                      'War die abwesende Pflegeperson den ganzen Tag verhindert ?',
                    // Select box (Less than 8 hours (default), More than 8 hours)
                    answers: '',
                    img: Logo,
                    type: 'submit',
                    required: false,
                    questionKey: 'durationOfAbsence',
                  },
                )
              }
              !representationByCommerciallyActive.activeDates[
                representationByCommerciallyActive.activeDates.length - 1
              ].availableDates.amount ||
              !representationByCommerciallyActive.activeDates[
                representationByCommerciallyActive.activeDates.length - 1
              ].availableDates.duration ||
              !representationByCommerciallyActive.activeDates[
                representationByCommerciallyActive.activeDates.length - 1
              ].durationOfAbsence
                ? dispatch(
                    setCommercially(
                      Object.keys(representationByCommerciallyActive)[0],
                      representationByCommerciallyActive.activeDates[
                        representationByCommerciallyActive.activeDates?.length - 1
                      ].availableDates.amount
                        ? '-'
                        : {
                            date: dates[currentIndexActive],
                            duration: '0 Std.',
                            amount: '€ 0',
                          },
                      representationByCommerciallyActive.activeDates.length - 1,
                      representationByCommerciallyActive.activeDates[
                        representationByCommerciallyActive.activeDates?.length - 1
                      ].availableDates.amount
                        ? 'durationOfAbsence'
                        : 'availableDates',
                      availableDays,
                    ),
                  )
                : dispatch(
                    setCommercially(
                      Object.keys(representationByCommerciallyActive)[
                        notObjIndexActive -
                          (representationByCommerciallyActive.activeDates?.length -
                            1)
                      ],
                      '-',
                      null,
                      null,
                    ),
                  )

              dispatch(setCommerciallyActive(newActiveQuiz, indexActive + 1))

              setParentState('')
            } else {
              if (
                ((representativePerson.personRepresenting === 'Nein' &&
                  representativePerson.commerciallyActive === 'Ja' &&
                  representativePerson.relationship) ||
                  (representativePerson.personRepresenting === 'Nein' &&
                    representativePerson.commerciallyActive === 'Nein' &&
                    representativePerson.relationship)) &&
                quizPrivate.length !== indexPrivate
              ) {
                const newPrivateQuiz = quizPrivate.map((item, i) => {
                  if (i === indexPrivate) {
                    return {
                      ...item,
                      answers:
                        typeof item.answers === 'object'
                          ? {
                              date: dates[currentIndexIndividual],
                              duration: '0 Std.',
                              amount: '€ 0',
                            }
                          : '-',
                    }
                  }
                  return item
                })

                if (
                  availableDays &&
                  representationByPrivateIndividual.individualDates.length !==
                    availableDays &&
                  (representationByPrivateIndividual.individualDates[
                    representationByPrivateIndividual.individualDates.length - 1
                  ].availableDates?.amount ||
                    representationByPrivateIndividual.individualDates[
                      representationByPrivateIndividual.individualDates.length - 1
                    ].availableDates?.amount === '0')
                ) {
                  newPrivateQuiz.splice(
                    indexPrivate + 1,
                    0,
                    {
                      answers: {
                        date: '',
                        duration: '',
                        amount: '',
                      },
                      img: Logo,
                      type: 'group',
                      required: false,
                      questionKey: 'availableDates',
                    },
                    {
                      question:
                        'War die abwesende Pflegeperson den ganzen Tag verhindert ?',
                      // Select box (Less than 8 hours (default), More than 8 hours)
                      answers: '',
                      img: Logo,
                      type: 'submit',
                      required: false,
                      questionKey: 'durationOfAbsence',
                    },
                  )
                }
                !representationByPrivateIndividual.individualDates[
                  representationByPrivateIndividual.individualDates.length - 1
                ].availableDates.amount ||
                !representationByPrivateIndividual.individualDates[
                  representationByPrivateIndividual.individualDates.length - 1
                ].availableDates.duration ||
                !representationByPrivateIndividual.individualDates[
                  representationByPrivateIndividual.individualDates.length - 1
                ].durationOfAbsence
                  ? dispatch(
                      setIndividual(
                        Object.keys(representationByPrivateIndividual)[0],
                        representationByPrivateIndividual.individualDates[
                          representationByPrivateIndividual.individualDates.length -
                            1
                        ].availableDates.amount
                          ? '-'
                          : {
                              date: dates[currentIndexIndividual],
                              duration: '0 Std.',
                              amount: '€ 0',
                            },
                        representationByPrivateIndividual.individualDates.length - 1,
                        representationByPrivateIndividual.individualDates[
                          representationByPrivateIndividual.individualDates.length -
                            1
                        ].availableDates.amount
                          ? 'durationOfAbsence'
                          : 'availableDates',
                        availableDays,
                      ),
                    )
                  : dispatch(
                      setIndividual(
                        Object.keys(representationByPrivateIndividual)[
                          representativePerson.personRepresenting === 'Nein' &&
                          representativePerson.commerciallyActive === 'Ja'
                            ? removedItem +
                              4 -
                              (representationByPrivateIndividual.individualDates
                                .length -
                                1)
                            : representationByPrivateIndividual.representingPersonByTransport ===
                                'Nein'
                              ? removedItem +
                                3 -
                                (representationByPrivateIndividual.individualDates
                                  .length -
                                  1)
                              : notObjIndex -
                                (representationByPrivateIndividual.individualDates
                                  .length -
                                  1)
                        ],
                        '-',
                        null,
                        null,
                      ),
                    )

                dispatch(setPrivateIndividual(newPrivateQuiz, indexPrivate + 1))
                setParentState('')
              }
            }
          }
        }
      }
    }
  }
  return (
    <PageComponent>
      <div className={cx(styles.challengeSteps)}>
        <Details notCareAnsweredIndex={notCareAnsweredIndex} />
        <div>
          <div ref={listRef}>
            <ul>
              {/*    <span>*/}
              {/*    Hallo, mein Name ist FLEXX-i und ich freue mich, Ihnen zu helfen!*/}
              {/*    Lassen Sie uns mit der Erstellung Ihres Antrags auf Verhinderungspflege beginnen. Beantworten Sie mir bitte folgende Fragen:*/}
              {/*</span>*/}
              <CareChallenge
                careReceipients={careReceipients}
                indexCare={indexCare}
                quizCare={quizCare}
                notCareAnsweredIndex={notCareAnsweredIndex}
                editList={editList}
                setEdit={setEdit}
                getTextValue={getTextValue}
                handleCareTextAnswer={handleCareTextAnswer}
                edit={edit}
                handleCancel={handleCancelCare}
                handleEdit={handleEditCare}
                messageRefs={messageRefs}
              />
              {(careReceipients.levelOfCare !== 'Nein' ||
                careReceipients.levelOfCare !== '1') &&
                careReceipients.legalRepresentation === 'Ja' &&
                quizCare?.length === indexCare && (
                  <LegalChallenge
                    quizLegal={quizLegal}
                    indexLegal={indexLegal}
                    legalRepresentations={legalRepresentations}
                    notLegalAnsweredIndex={notLegalAnsweredIndex}
                    editList={editList}
                    setEdit={setEdit}
                    getTextValue={getTextValue}
                    handleLegalTextAnswer={handleLegalTextAnswer}
                    edit={edit}
                    handleEdit={handleEditeLegal}
                    handleCancel={handleCancelLegal}
                    messageRefs={messageRefs}
                  />
                )}
              {((careReceipients.levelOfCare === 'Nein' &&
                careReceipients.levelOfCare === '1' &&
                ((careReceipients.legalRepresentation === 'Ja' &&
                  quizLegal?.length !== indexLegal) ||
                  (careReceipients.legalRepresentation === 'Nein' &&
                    quizCare?.length !== indexCare))) ||
                (careReceipients.levelOfCare !== 'Nein' &&
                  careReceipients.levelOfCare !== '1' &&
                  ((careReceipients.legalRepresentation === 'Ja' &&
                    quizLegal?.length === indexLegal) ||
                    (careReceipients.legalRepresentation === 'Nein' &&
                      quizCare?.length === indexCare)))) && (
                <AuthChallenge
                  authorizedPerson={authorizedPerson}
                  quizAuth={quizAuth}
                  notAuthAnsweredIndex={notAuthAnsweredIndex}
                  editList={editList}
                  setEdit={setEdit}
                  getTextValue={getTextValue}
                  handleAuthTextAnswer={handleAuthTextAnswer}
                  edit={edit}
                  handleEdit={handleEditAuth}
                  handleCancel={handleCancelAuth}
                  messageRefs={messageRefs}
                />
              )}
              {((careReceipients.legalRepresentation === 'Ja' &&
                quizLegal.length === indexLegal) ||
                (careReceipients.legalRepresentation === 'Nein' &&
                  quizCare.length === indexCare)) &&
                ((careReceipients.levelOfCare !== 'Nein' &&
                  careReceipients.levelOfCare !== '1' &&
                  authorizedPerson.periodOfCare !== 'Unter 6 monate' &&
                  authorizedPerson.professionalCarer !== 'Ja' &&
                  authorizedPerson.reasonForTheAbsence &&
                  quizAuth.length !== indexAuth) ||
                  (careReceipients.levelOfCare !== 'Nein' &&
                    careReceipients.levelOfCare !== '1' &&
                    authorizedPerson.periodOfCare !== 'Unter 6 monate' &&
                    authorizedPerson.professionalCarer !== 'Ja' &&
                    authorizedPerson.reasonForTheAbsence &&
                    quizAuth.length === indexAuth)) && (
                  <RepresentativeChallenge
                    quizRep={quizRep}
                    representativePerson={representativePerson}
                    notRepAnsweredIndex={notRepAnsweredIndex}
                    editList={editList}
                    setEdit={setEdit}
                    getTextValue={getTextValue}
                    handleRepTextAnswer={handleRepTextAnswer}
                    edit={edit}
                    handleEdit={handleEditRep}
                    handleCancel={handleCancelRep}
                    messageRefs={messageRefs}
                  />
                )}
              {((careReceipients.legalRepresentation === 'Ja' &&
                quizLegal.length === indexLegal) ||
                (careReceipients.legalRepresentation === 'Nein' &&
                  quizCare.length === indexCare)) &&
                careReceipients.levelOfCare !== 'Nein' &&
                careReceipients.levelOfCare !== '1' &&
                representativePerson.personRepresenting === 'Ja' &&
                quizRep.length === indexRep && (
                  <CommerciallyActiveChallenge
                    quizActive={quizActive}
                    indexActive={indexActive}
                    notActiveAnsweredIndex={notActiveAnsweredIndex}
                    editList={editList}
                    setEdit={setEdit}
                    getTextValue={getTextValue}
                    handleActiveTextAnswer={handleActiveTextAnswer}
                    edit={edit}
                    handleEdit={handleEditActive}
                    handleCancel={handleCancelActive}
                    messageRefs={messageRefs}
                  />
                )}
              {((careReceipients.legalRepresentation === 'Ja' &&
                quizLegal.length === indexLegal) ||
                (careReceipients.legalRepresentation === 'Nein' &&
                  quizCare.length === indexCare)) &&
                ((representativePerson.personRepresenting === 'Nein' &&
                  representativePerson.commerciallyActive === 'Nein') ||
                  (representativePerson.personRepresenting === 'Nein' &&
                    representativePerson.commerciallyActive === 'Ja')) &&
                quizRep.length === indexRep && (
                  <PrivateIndividualChallenge
                    quizPrivate={quizPrivate}
                    indexPrivate={indexPrivate}
                    notAPrivateAnsweredIndex={notPrivateAnsweredIndex}
                    editList={editList}
                    setEdit={setEdit}
                    getTextValue={getTextValue}
                    handlePrivateTextAnswer={handlePrivateTextAnswer}
                    edit={edit}
                    handleEdit={handleEditPrivate}
                    handleCancel={handleCancelPrivate}
                    messageRefs={messageRefs}
                  />
                )}
              {/*{representationByPrivateIndividual.iHave &&  <li className={styles.finalQuestion}>*/}
              {/*    <img src={finalQuestions?.img} alt={finalQuestions?.img}/>*/}
              {/*    <p>*/}
              {/*        {finalQuestions?.question}*/}
              {/*    </p>*/}
              {/*</li>}*/}
            </ul>
            {windowWidth.width < 1320 && showScrollDownButton && (
              <button onClick={scrollToBottom}>
                {/*{scrollToBottom ? "Scroll to Bottom" : "Scroll to Top"}*/}
                <img src={ArrowRight} alt={ArrowRight} />
              </button>
            )}
          </div>
        </div>
        <div>
          <div className={styles.messageRobot}>
            <p>
              <TypingEffect text={helloMessage} speed={50} />
            </p>
            <img src={Vector} alt={Vector} />
          </div>
          <div>
            <img src={Robotik} alt={Robotik} />
            {showScrollDownButton && (
              <button onClick={scrollToBottom}>
                <img src={ArrowRight} alt={ArrowRight} />
              </button>
            )}
          </div>
          {/*{representativePerson.relationship && <div className={styles.infoRobot}>*/}
          {/*    <p>*/}
          {/*        <TypingEffect text={message} speed={50}/>*/}
          {/*    </p>*/}
          {/*</div>}*/}
        </div>
        {windowWidth.width < 768 &&
          pathname === '/startChallenge' &&
          !isDisabledCare &&
          !isDisabledLegal &&
          !isDisabledAuth &&
          !isDisabledRep &&
          !isDisabledActive &&
          !isDisabledPrivate && (
            <button onClick={handleSkip}>
              <p>Überspringen</p>
            </button>
          )}
      </div>
    </PageComponent>
  )
}
export default ChallengeSteps
