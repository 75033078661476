import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styles from '../modalComponent.module.scss'
import Arrow from '../../../../../icons/smallArrow.svg'
import RightArrow from '../../../../../icons/rightArrowIcon.svg'
import LeftArrow from '../../../../../icons/leftArrowIcon.svg'
import Calendar from 'react-calendar'
import { useDispatch, useSelector } from 'react-redux'
import FlexiiLogo from '../../../../../icons/flexiiLogo.svg'
import { insuranceCompanies } from '../../../../../constants/lists/relationshipList'
import {
  setCareReceipients,
  setEmptyLegal,
} from '../../../../../redux/actions/careReceipients'
import {
  setCareQuizQuestions,
  setEditFromSummary,
  setLegalRepresentation,
} from '../../../../../redux/actions/quizQuestions'
import Button from '../../../../UiKitComponents/Button'
import { closeConfirmationModal } from '../../../../../redux/actions/confirmationModal'
import cx from 'classnames'
import { calculateAge } from '../../../../../constant/constants'
import PhoneNumberField from '../../../../ReusableField/PhoneNumberField'
import { handlePhoneNumberFieldChange } from '../../../../../redux/actions/Auth'
import countryPickerData from '../../../../../constant/countryPickerData'
import { quizQuestions } from '../../../../../redux/defaultStates'

const CareModalComponent = ({ item, careIndex }) => {
  const dispatch = useDispatch()
  const {
    quizCareReceipients: { quizCare, indexCare },
  } = useSelector((state) => state.quizQuestions)
  const [option, setOption] = useState(false)
  const { careReceipients } = useSelector((state) => state)
  const [answerr, setAnswerr] = useState(
    item.type !== 'date' ? item.answers || '' : '',
  )
  // const { phoneNumberObj } = useSelector((state) => state.auth)
  const [phoneNumberObj, setPhoneNumberData] = useState({})
  const defaultPhoneObj = useSelector((state) => state.auth).phoneNumberObj

  useEffect(() => {
    if (item.type === 'phoneNumber') {
      if (item.answers !== '-') {
        console.log('item.answers', item.answers)
        const mobileArr = item.answers?.slice(1)?.split(' ')

        const countryCode = countryPickerData.find(
          (countryObj) => countryObj.callingCode === mobileArr[0],
        ).key

        setPhoneNumberData({
          callingCode: mobileArr[0],
          number: mobileArr[1],
          countryCode,
        })
      } else {
        setPhoneNumberData(defaultPhoneObj)
      }
    }
  }, [])

  const handleChangePhone = (newPhoneNumberObj) => {
    setPhoneNumberData(newPhoneNumberObj)
    // dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
  }
  const handleChange = (e) => {
    setAnswerr(e.currentTarget.value)
  }
  const clickCareOption = (e) => {
    setAnswerr(e.target.innerText)
    setOption(false)
  }
  const clickOption = () => {
    if (!option) {
      setOption(true)
    } else {
      setOption(false)
    }
  }
  const handleChangeCalendar = (e) => {
    setAnswerr(moment(e).format('DD.MM.YYYY'))
  }
  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split('.')
    const date = new Date(year, month - 1, day)
    return date.toString()
  }

  const handleCareAnswer = (index) => {
    // if(!answerr) return
    const newQuiz = quizCare.map((item, i) => {
      console.log('answerr', answerr)
      if (i === index) {
        return {
          ...item,
          // answers: answerr,
          answers:
            item.type !== 'phoneNumber'
              ? answerr
              : item.type === 'phoneNumber' && (!answerr || answerr === '-')
                ? '-'
                : `+${phoneNumberObj.callingCode} ` +
                  '' +
                  `${phoneNumberObj.number}`,
          returnQuestion:
            item.question ===
              'Wurde bereits ein Pflegegrad erteilt ? Wenn ja, welcher ? Bitte wählen Sie aus der Liste aus.' &&
            (answerr === 'Nein' || answerr === '1')
              ? 'Ersatz von Kosten der Verhinderungspflege ist derzeit nicht möglich, mindestens Pflegegrad 2 ist erforderlich.'
              : calculateAge(convertToDate(answerr)) < 25
                ? 'Bitte beachten Sie : Bei Antragstellern mit Pflegegrad 4 oder 5 bis einschliesslich 25 Jahre kann die Berechnung Fehler aufweisen.'
                : '',
        }
      }
      return item
    })
    dispatch(
      setCareReceipients(
        Object.keys(careReceipients)[index],
        // Object.keys(careReceipients)[index] === 'phoneNumber' ? `+${phoneNumberObj.callingCode} `+``+`${answerr}` : answerr
        Object.keys(careReceipients)[index] !== 'phoneNumber'
          ? answerr
          : item.type === 'phoneNumber' && (!answerr || answerr === '-')
            ? '-'
            : `+${phoneNumberObj.callingCode} ` + '' + `${phoneNumberObj.number}`,
      ),
    )
    if (answerr === 'Nein') {
      dispatch(
        setLegalRepresentation(
          [...quizQuestions.quizLegalRepresentation.quizLegal],
          0,
        ),
      )
      dispatch(
        setEmptyLegal({
          lastName: '',
          name: '',
          address: '',
          houseNumber: '',
          zipCode: '',
          city: '',
          phoneNumber: '',
          document: '',
        }),
      )
    }
    dispatch(setCareQuizQuestions(newQuiz, indexCare))
    setAnswerr('')
  }

  return (
    <section className={styles.modalComponent}>
      <p className={styles.odd}>
        <img src={item?.img && FlexiiLogo} alt={FlexiiLogo} />
        <p>{item.question}</p>
      </p>
      {item.type === 'date' && (
        <Calendar
          onChange={(e) => handleChangeCalendar(e)}
          value={
            careReceipients.birthday === '-' && !answerr
              ? new Date(1965, moment().month(), moment().date())
              : careReceipients.birthday !== '-' && !answerr
                ? new Date(convertToDate(careReceipients.birthday))
                : convertToDate(answerr)
          }
          maxDate={new Date()}
          className={styles.reactCalendar}
          nextLabel={<img src={RightArrow} alt={'next'} />}
          prevLabel={<img src={LeftArrow} alt={'Previous'} />}
          next2Label={null}
          prev2Label={null}
          locale={'de'}
        />
      )}
      {item.type === 'phoneNumber' && (
        <PhoneNumberField
          onChange={handleChangePhone}
          phoneNumberObj={phoneNumberObj}
          setAnswerr={setAnswerr}
          value={answerr}
        />
      )}
      {item.type === 'rate' && (
        <div className={styles.rate}>
          <input
            type={'submit'}
            value={'Nein'}
            onClick={(e) => handleChange(e)}
            autoFocus={careReceipients.levelOfCare === 'Nein'}
          />
          <input
            type={'submit'}
            value={1}
            onClick={(e) => handleChange(e)}
            autoFocus={careReceipients.levelOfCare === '1'}
          />
          <input
            type={'submit'}
            value={2}
            onClick={(e) => handleChange(e)}
            autoFocus={careReceipients.levelOfCare === '2'}
          />
          <input
            type={'submit'}
            value={3}
            onClick={(e) => handleChange(e)}
            autoFocus={careReceipients.levelOfCare === '3'}
          />
          <input
            type={'submit'}
            value={4}
            onClick={(e) => handleChange(e)}
            autoFocus={careReceipients.levelOfCare === '4'}
          />
          <input
            type={'submit'}
            value={5}
            onClick={(e) => handleChange(e)}
            autoFocus={careReceipients.levelOfCare === '5'}
          />
        </div>
      )}
      {item.type === 'option' && (
        <div
          className={styles.optionHid}
          onClick={clickOption}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleCareAnswer()
          }}
        >
          <div
            className={cx(styles.select, {
              [styles.imgRotate]: !!option,
            })}
          >
            <img src={Arrow} alt={Arrow} />
            {answerr ? answerr : careReceipients.insurance}
          </div>
          {!!option && (
            <div className={styles.option}>
              {insuranceCompanies
                .sort((a, b) => a.localeCompare(b))
                .map((item) => {
                  return <div onClick={clickCareOption}>{item}</div>
                })}
            </div>
          )}
        </div>
      )}
      {item.type === 'submit' && (
        <span>
          <input
            type={'submit'}
            value={'Ja'}
            onClick={(e) => handleChange(e)}
            className={styles.care}
            autoFocus={careReceipients.legalRepresentation === 'Ja'}
          />
          <input
            type={'submit'}
            value={'Nein'}
            onClick={(e) => handleChange(e)}
            className={styles.care}
            autoFocus={careReceipients.legalRepresentation === 'Nein'}
          />
        </span>
      )}
      <div>
        <Button
          small
          primaryOutlined
          onClick={() => {
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Abbrechen'}
        </Button>
        <Button
          primary
          small
          onClick={() => {
            handleCareAnswer(careIndex)
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Speichern'}
        </Button>
      </div>
    </section>
  )
}

export default CareModalComponent
